.slick-slider {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slick-list {
  position: relative;
  display: block !important;
  overflow: hidden;
  padding: 0px !important;
  width: 100%;
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
}
