@import url("https://fonts.googleapis.com/css2?family=Alex+Brush&family=Imprima&family=Inter:wght@400;500;600;700&display=swap");

:root {
  --dark_blue: #10242e;
  --middle_blue: #2d4c56;
  --light_wood: #bc9d7f;
  --middle_wood: #ae6b39;
  --cream_light: #e3e0db;
  --bg_color: #e3e3e3;
}
* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}
html,
body {
  max-width: 100%;
  overflow-x: hidden;
}
body {
  background-color: var(--bg_color);
}

.lang-container {
  position: relative;
  & .lang-icons {
    position: absolute;
    right: 15px;
    top: 5px;
    display: flex;

    cursor: pointer;
    & img {
      z-index: 1;
      margin: 5px;
      width: 40px;
    }
  }
}

.header {
  background-color: var(--cream_light);
  & .header-items {
    margin: 0 auto;
    width: 80%;
    position: relative;
    & img {
      width: 14%;
      vertical-align: middle;
    }
    & .navigation-desktop {
      & nav {
        display: flex;
        text-align: center;
        word-spacing: 5px;
        padding: 5px;
        font-family: "Imprima", sans-serif;
        font-size: 1.4rem;
        letter-spacing: 0.18rem;
        right: 0;
        color: var(--dark_blue);
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        & a {
          padding: 5px;
          text-decoration: none;
          color: var(--dark_blue);
          &.active {
            color: var(--middle_wood);
          }
        }
        & p {
          font-size: 1.5em;
          padding: 0 2px;
          transform: scale(0.5, 1.3);
        }
      }
    }
  }
}

.btn-scroll-to-top {
  bottom: 30px;
  right: 30px;
  position: fixed;
  font-size: 3rem;
  color: var(--light_wood);
  cursor: pointer;
  border: none;
  border-radius: 55px;
  z-index: 1;
}
.btn-scroll-to-top:hover {
  transition: 1s;
  scale: 1.2;
}

.pocetna {
  & .main-info-container {
    text-align: justify;
    width: 70%;
    background: rgb(155, 103, 52);
    background: linear-gradient(
      294deg,
      rgba(155, 103, 52, 1) 0%,
      rgba(216, 157, 100, 1) 100%
    );
    margin: 20px auto;
    margin-top: 80px;
    display: flex;
    padding: 30px 20px;
    border-radius: 30px;
    & .left {
      position: relative;
      flex: 4;
      width: 100%;
      justify-content: center;
      margin: 0 auto;
      & img {
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        width: fit-content;
        display: flex;
        margin: 0 auto;
        justify-content: center;
        width: 100%;
        height: auto;
      }
    }
    & .right {
      flex: 4;
      color: var(--cream_light);
      padding: 10px 40px;
      & .right-cloud {
        font-family: "Alex Brush", cursive;
        width: 80%;
        font-size: 5vw;
        text-align: center;
        margin: 5px auto;
        border-radius: 30px;
        background-color: rgba($color: #ae6b39, $alpha: 0.4);
      }
      & p {
        line-height: 130%;
        margin: 20px 0;
        font-size: 1.05vw;
        text-shadow: 2px 2px 10px #474747;
      }
    }
  }

  & .perks-container {
    width: 70%;
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
    margin: 50px auto;
    & .item {
      text-align: center;
      width: 170px;
      height: 180px;
      padding-top: 20px;
      box-shadow: 1px 1px 20px #8a8a8a;
      background-color: var(--cream_light);
      color: var(--dark_blue);
      border-radius: 45px;
      & .icon {
        font-size: 6rem;
      }
      & .info {
        width: 55%;
        margin: 0 auto;
        position: relative;
        top: 20%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        & p {
          font-size: larger;
        }
      }
    }
  }
  & .btnMore {
    position: relative;
    height: 130px;
  }

  & .main-images-container {
    width: 100%;
    background-color: var(--middle_blue);
    text-align: center;
    font-size: 1.4vw;
    color: var(--cream_light);
    padding: 10px;
    & p {
      line-height: 2vw;
      width: 70%;
      margin: 50px auto;
    }
    & .slider-container {
      width: 80%;
      margin: 0 auto;
      height: 400px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      & img {
        // min-height: 400px;
        // object-fit: cover;
        margin: 0 20px;
        border-radius: 15px;
      }
    }
    & .arrow {
      font-size: 50px;
      padding: 20px;
      cursor: pointer;
      color: var(--lightgrey);
    }
    & button {
      width: 40%;
      background-color: var(--middle_wood);
      color: var(--cream_light);
      font-size: 1.5rem;
      padding: 15px;
      border-radius: 10px;
      font-weight: 600;
      margin-top: 25px;
      margin-bottom: 25px;
      cursor: pointer;
    }
  }

  & .cover-container {
    text-align: center;
    margin: 0 auto;
    justify-content: center;
    height: 100%;
    & .cover-text-container {
      width: 100%;
      margin: 0 auto;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      z-index: 1;
      & .main-name {
        font-family: "Alex Brush", cursive;
        font-size: 6vw;
        width: 60%;
        padding: 30px;
        color: var(--cream_light);
        text-shadow: 5px 10px 10px #0f0f0f;
        background-color: rgba($color: #bc9d7f, $alpha: 0.4);
      }
      & p {
        color: var(--cream_light);
        font-size: 1.2vw;
        margin: 20px auto;
        left: 0;
        right: 0;
        letter-spacing: 3px;
      }
      & a {
        width: 50%;
        text-decoration: none;
        color: var(--cream_light);
      }

      & .rezerviraj {
        cursor: pointer;
        margin-top: 60px;
        border-radius: 15px;
        width: 20%;
        padding: 20px;
        font-size: 1.5vw;
        font-weight: 600;
        background-color: var(--middle_blue);
        box-shadow: 4px 4px 12px #020202;
        & a {
          width: 100%;
          padding: 0;
        }
        &:hover {
          background-color: var(--dark_blue);
          transition: 1s;
        }
      }
    }
  }

  & .what-to-visit-container {
    width: 80%;
    margin: 50px auto 10px;
    & p {
      text-align: center;
      font-size: 2vw;
    }
    & .top-part {
      display: flex;
      margin: 0 auto;
      justify-content: center;
      & table {
        margin: 8px 20px;
        font-size: 16px;
        background-color: #fff;
        border-collapse: collapse;
        & th {
          background-color: var(--dark_blue);
          color: var(--cream_light);
          padding: 10px 20px 10px 0;
        }
        & td {
          padding: 10px 20px;
          & a {
            text-decoration: none;
            color: var(--dark_blue);
          }
        }
        & tr {
          border-top: 3px solid rgba(212, 212, 212, 0.5);
        }
      }
      & .video-responsive {
        margin: 40px 20px;
        & iframe {
          width: 820px;
          height: 100%;
        }
      }
    }
    & .description {
      width: 70%;
      margin: 0 auto;
      text-align: justify;
      line-height: 2vw;
      padding: 10px;
      font-size: 1vw;
      & a {
        cursor: pointer;
        color: var(--middle_wood);
        font-weight: 600;
        text-decoration: none;
      }
    }
  }

  & .btn-detalji-container {
    width: 60%;
    margin: 0 auto;
    position: relative;
    height: 130px;
    & .btnDetalji {
      cursor: pointer;
      position: absolute;
      right: 0;
      width: fit-content;
      font-size: 25px;
      padding: 15px;
      text-decoration: underline;
      background-color: var(--cream_light);
      color: var(--dark_blue);
      box-shadow: 2px 4px 12px #ae6b39;
    }
  }

  & .map-container {
    margin: 0 auto;
    width: fit-content;
    & iframe {
      width: 800px;
      height: 450px;
    }
  }
}

.oapartmanu {
  & .about-container {
    & .about-top-container {
      width: 80%;
      margin: 60px auto;
      display: flex;
      & .about-text {
        width: 50%;
        padding: 0px 10px;
        text-align: justify;
        & p {
          font-size: 1.2rem;
          word-spacing: 0.9rem;
          line-height: 2rem;
          margin: 10px 20px;
        }
        & .about-title {
          text-align: center;
          font-size: 1.6rem;
          font-weight: 500;
          margin: 60px 0 20px;
        }
      }
      & .slider-container {
        width: 50%;
        margin: 0 auto;
        & img {
          margin: 0 20px;
          border-radius: 5px;
        }
      }
      & .arrow {
        cursor: pointer;
        font-size: 50px;
      }
    }

    & .content-container {
      background-color: var(--dark_blue);
      & .content-title {
        text-align: center;
        color: var(--cream_light);
        font-size: 2rem;
        padding: 50px;
      }
      & .groups {
        display: flex;
        width: 80%;
        margin: 20px auto;
        padding: 10px 0 70px;
        gap: 20px;
        & .left,
        .right {
          background-color: var(--middle_blue);
          padding: 30px;
          border-radius: 20px;
          color: var(--cream_light);
        }
        & .left {
          width: 25%;
          & ul {
            font-size: 1.7rem;
            margin-top: 50px;
            & li {
              padding: 5px;
              margin-left: 20px;
            }
          }
        }
        & .right {
          width: 75%;
          column-count: 3;
          & ul {
            padding: 0 20px;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            & li {
              padding: 5px;
              font-size: 1.2rem;
            }
          }
        }
      }
    }
    & .more-info-container {
      width: 70%;
      margin: 70px auto;
      font-size: 5rem;
      color: var(--middle_wood);
      display: flex;
      align-items: center;
      & .info {
        font-size: 1.5rem;
        margin-left: 100px;
        & p {
          padding: 5px;
          color: var(--dark_blue);
        }
      }
    }
  }
}

.cjenik {
  & table {
    margin: 8px auto;
    font-size: 1.2rem;
    text-align: center;
    background-color: #fff;
    border-collapse: collapse;
    & th {
      background-color: var(--dark_blue);
      color: var(--cream_light);
      padding: 20px;
    }
    & td {
      padding: 15px;
    }
    & tr {
      border-top: 3px solid rgba(212, 212, 212, 0.5);
    }
  }
  & .popust {
    text-align: center;
  }
  & .text-info {
    border: 7px var(--light_wood) solid;
    margin: 50px auto;
    font-size: 1.2vw;
    color: var(--dark_blue);
    width: 60%;
    border-radius: 30px;
    padding: 10px 30px;
    & p {
      margin: 15px 0;
      & a {
        text-decoration: none;
        color: var(--middle_wood);
      }
    }
  }
}

.kontakt {
  & .step-container {
    margin: 0 auto;
    width: 70%;
    display: block;
    & .text {
      display: block;
      & p {
        color: var(--dark_blue);
        margin: 15px 25px;
        font-size: 1.15vw;
        line-height: 150%;
        &.step-title {
          font-size: 1.7vw;
          font-weight: 500;
          margin: 60px 0 20px;
        }
        & a {
          //text-decoration: none;
          color: var(--middle_wood);
        }
      }
    }
    & .step-one {
      & .middle {
        width: 100%;
        display: flex;
        position: relative;
        & .contact-form-container {
          margin-top: 20px;
          width: 65%;
          & .inputs {
            display: flex;
            gap: 20px;
            & label {
              display: block;
              font-size: 1rem;
              color: #4d4d4d;
            }
            & .input-group {
              width: 100%;
              display: block;
            }
            & input,
            textarea {
              background-color: var(--cream_light);
              width: 100%;
              display: block;
              border-radius: 10px;
              font-size: 1rem;
              padding: 10px;
              margin: 10px 0 20px;
              box-shadow: 5px 5px 4px #cbcbcb;
              border: 2px var(--middle_wood) solid;
            }
            & input {
              height: 50px;
            }
            & textarea {
              height: 450px;
              resize: vertical;
            }
          }
          & #btnSend {
            cursor: pointer;
            background-color: var(--middle_wood);
            text-align: center;
            color: var(--cream_light);
            font-weight: 500;
            width: 100%;
            padding: 10px;
            font-size: 2vw;
            border-radius: 10px;
            margin: 15px 0;
            &:hover {
              transition: 1s;
              background-color: var(--light_wood);
            }
          }
        }
        & .info-container {
          margin: 0;
          right: 0;
          position: absolute;
          top: 50%;
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
        }
      }
    }

    & .step-two {
      & .bank-details-container {
        margin: 0 auto;
        padding: 20px;
        box-shadow: 5px 5px 4px #a8a8a8;
        width: fit-content;
        background-color: var(--cream_light);
        line-height: 1.8rem;
        border-radius: 15px;
      }
    }
  }
}

.omjestu {
  & .title {
    margin: 50px 0;
  }
  & .zamrsje-container {
    & .cover {
      width: 100%;
      position: relative;
      margin: 0 auto;
      & img {
        width: 100%;
      }
      & .description {
        width: 90%;
        margin: 0 auto;
        padding: 25px;
        background-color: rgba($color: #e3e0db, $alpha: 0.8);
        color: var(--dark_blue);
        font-size: 1.1rem;
        top: 30%;
        right: 0;
        left: 0;
        position: absolute;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        z-index: 1;
        & p {
          text-align: justify;
          letter-spacing: 1.5px;
          padding: 10px;
          font-weight: 500;
          line-height: 150%;
        }
      }
    }
    & .images {
      width: 80%;
      margin: 100px auto;
      display: flex;
      & img {
        width: 33%;
      }
    }
    & .text {
      width: 60%;
      margin: 0 auto;
      & p {
        padding: 20px;
        text-align: justify;
        font-size: 1.1rem;
        letter-spacing: 1.5px;
        line-height: 150%;
      }
    }

    & .kapela {
      width: 60%;
    }
  }
  & .recica-container {
    width: 100%;
    & .top-part {
      background-color: var(--light_wood);
      & .split-divs {
        width: 90%;
        margin: 0px auto;
        display: flex;
        justify-content: center;
        gap: 40px;
        & .old {
          padding: 80px 0px;
          width: 40%;
          position: relative;
          & .color-overlay {
            opacity: 70%;
            background-color: var(--bg_color);
            height: 100%;

            & img {
              width: 100%;
              height: 100%;
              opacity: 40%;
            }
            & .text-over {
              padding: 10px;
              letter-spacing: 1.5px;
              line-height: 150%;
              font-size: 1rem;
              font-weight: 500;
              width: 80%;
              text-align: justify;
              background-color: rgba($color: #e3e0db, $alpha: 0.6);
              & p {
                padding: 5px;
              }
            }
          }
        }
        & .new {
          padding: 80px 0;
          width: 50%;

          & img {
            width: 50%;
            height: 100%;
          }
        }
      }
    }
    & .bottom-part {
      width: 90%;
      margin: 0 auto;
    }
  }
  & .karlovac-container {
    & .cover {
      position: relative;
      width: 100%;
      & img {
        width: 100%;
      }
      & .text-container {
        background-color: rgba($color: #e3e0db, $alpha: 0.85);
        width: 40%;
        right: 5%;
        padding: 40px;
        text-align: justify;
        font-size: 1.1rem;
        letter-spacing: 1.1px;
        line-height: 150%;
        & p {
          padding: 5px;
        }
      }
    }
    & .attractions-container {
      & .attraction {
        width: 70%;
        margin: 40px auto;
        & a {
          color: var(--cream_light);
          display: flex;
          gap: 20px;
          text-decoration: none;
          & .text {
            position: relative;
            width: 50%;
            & h2 {
              text-transform: uppercase;
              font-size: 2rem;
              letter-spacing: 2px;
              margin-bottom: 15px;
            }
            & p {
              font-size: 1.1rem;
              line-height: 150%;
              letter-spacing: 1.5px;
            }
            & .inside {
              padding: 50px;
            }
          }
          & .image {
            width: 50%;
            display: block;
            padding: 40px;
            & img {
              width: 100%;
              height: 100%;
            }
            & p {
              font-size: 0.6rem;
              text-align: right;
            }
          }
        }
        &:nth-child(odd) {
          background-color: var(--middle_wood);
        }
        &:nth-child(even) {
          background-color: var(--middle_blue);
        }
      }
      & .more-info {
        text-align: center;
        width: 65%;
        margin: auto;
        font-size: 1.2rem;
        letter-spacing: 1.2px;
        line-height: 150%;
        & a {
          text-decoration: none;
          color: var(--middle_wood);
          font-weight: 500;
        }
      }
    }
  }
  & .other-container {
    & .location {
      margin: 80px auto;
      width: 100%;
      position: relative;
      &.zg {
        width: 90%;
      }
      & img {
        width: 100%;
      }
      & .description {
        background-color: rgba($color: #cbcbcb, $alpha: 0.8);
        position: absolute;
        color: var(--dark_blue);
        top: 70%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        z-index: 1;
        padding: 30px;
        width: 50%;
        text-align: center;
        &.right {
          right: 5%;
        }
        & h2 {
          font-size: 2rem;
        }
        & p {
          padding: 20px;
          line-height: 150%;
          font-size: 1.2rem;
        }
        &.jad {
          width: 35%;
        }
      }
    }
    & .gastro {
      background-color: var(--light_wood);
      & .gastro-content {
        display: flex;
        width: 90%;
        margin: 0 auto;
        padding: 20px;
        & .gastro-left {
          background-color: var(--cream_light);
          display: flex;
          margin: 20px;
          position: relative;
          width: 100%;
          & .down {
            top: 97%;
            font-size: 0.5rem;
            color: var(--cream_light);
          }
          & p {
            text-align: justify;
            font-size: 1.1rem;
            letter-spacing: 1.5px;
            line-height: 150%;
            padding: 5px 20px;
          }
          & .left {
            width: 50%;
            & img {
              width: 100%;
              height: 100%;
            }
          }
          & .right {
            width: 50%;
          }
        }
        & .gastro-right {
          position: relative;
          margin-right: 0%;
          & p {
            padding-top: 25%;
            font-size: 1rem;
            color: var(--cream_light);
            text-shadow: 2px 2px 5px #727272;
            text-align: center;
          }
        }
      }
      & h2 {
        text-align: center;
        padding: 30px 0 0px;
        font-size: 2rem;
      }
    }
    & .bottom-sentence {
      text-align: center;
      margin: 70px;
      font-size: 1.7rem;
    }
  }
  & .split {
    display: flex;
    width: 70%;
    margin: 80px auto;
    & .left {
      width: 50%;
      & img {
        width: 100%;
        height: 100%;
        box-shadow: 0px 4px 12px #979797;
      }
    }
    & .right {
      width: 50%;
      background-color: var(--cream_light);
      padding: 20px;
      box-shadow: 0px 4px 12px #979797;
      position: relative;
      & p {
        text-align: justify;
        font-size: 1.1rem;
        letter-spacing: 1.5px;
        line-height: 150%;
        padding: 20px;
      }
    }
  }
}

.title {
  margin: 30px auto;
  text-align: center;
  color: var(--dark_blue);
  & hr {
    border: 1px var(--middle_wood) solid;
    margin: 10px auto;
    &.short {
      width: 150px;
    }
    &.long {
      width: 300px;
    }
  }
}

.info-container {
  color: var(--middle_blue);
  margin: 40px auto;
  font-size: 1.35rem;
  width: 33%;
  justify-content: center;
  align-items: center;
  & .item {
    margin: 20px 0;
    display: flex;
    & .icon {
      width: 50px;
      margin-right: 20px;
    }
    & .info {
      max-width: 400px;
    }
  }
}

.footer-container {
  margin-top: 80px;
  background-color: var(--dark_blue);
  & .footer-boxes {
    width: 70%;
    display: flex;
    gap: 20px;
    justify-content: center;
    margin: 0px auto;
    & .footer-box {
      background-color: var(--middle_blue);
      width: 30%;
      padding: 25px 10px;
      margin: 75px 0;
      color: var(--cream_light);
      & .box-title {
        text-align: center;
        font-size: 1.6vw;
        font-weight: 600;
      }
      & .box-data {
        margin: 15px;
        padding: 10px;
        & a {
          display: block;
          text-decoration: none;
          color: var(--cream_light);
          padding: 5px;
        }
      }
    }
  }
}

.hidden {
  visibility: hidden;
  display: none;
}

.move-up-middle {
  // vertical align of div in the middle
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}

.center-abs {
  // centriranje absolute diva
  margin: 0 auto;
  left: 0;
  right: 0;
}

// za mobitel
@media only screen and (max-width: 1050px) {
  .lang-container {
    & .lang-icons {
      left: 0;
      & img {
        margin: 15px 5px;
        width: 40px;
      }
    }
  }
  .header {
    & .header-items {
      width: 100%;
      & .image {
        display: flex;
        justify-content: center;
        & img {
          width: 150px;
          margin: 0 auto;
        }
      }
      & .mobile {
        position: relative;
        & .icons {
          position: absolute;
          right: 3%;
          z-index: 2;
        }
        & .iconBar {
          &.abs {
            position: absolute;
          }
          background-color: var(--middle_wood);
          width: 12px;
          height: 12px;
          padding: 20px;
          border-radius: 10px;
          color: var(--bg_color);
          margin-top: 10px;
          cursor: pointer;
        }
        & .nav-bar {
          background-color: var(--light_wood);
          position: relative;
          width: 100%;
          z-index: 1;
          & a {
            display: block;
            text-align: center;
            text-decoration: none;
            word-spacing: 5px;
            padding: 15px;
            font-family: "Imprima", sans-serif;
            font-weight: bold;
            color: var(--dark_blue);
          }
        }
      }
    }
  }
  .pocetna {
    & .main-info-container {
      display: block;
      width: 95%;
      padding: 15px;
      margin-top: 120px;
      & .left {
        position: static;
        & img {
          position: static;
          top: 0%;
          -ms-transform: translateY(0%);
          transform: translateY(0%);
        }
      }
      & .right {
        padding: 0px;
        & .right-cloud {
          width: 90%;
          font-size: 3rem;
          margin: 15px auto;
        }
        & p {
          margin: 20px 0;
          font-size: 16px;
          text-shadow: 2px 2px 10px #474747;
        }
      }
    }

    & .perks-container {
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      padding: 0px;
      margin: 20px auto;
      & .item {
        margin: 10px 5px;
        width: 130px;
        padding: 20px;
        height: 130px;
        padding-top: 18px;
        border-radius: 15px;
        & .icon {
          font-size: 3.5rem;
        }
        & .info {
          width: 100%;
          margin: 0 auto;
          position: relative;
          top: 20%;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          text-align: center;
          & p {
            font-size: medium;
          }
        }
      }
    }

    & .main-images-container {
      font-size: 1.3rem;
      margin-top: 40px;
      & p {
        width: 90%;
        line-height: 150%;
        text-align: justify;
        margin: 30px auto;
      }
      & .slider-container {
        width: 100%;
        justify-content: center;
        margin: 0 auto;
        position: relative;
        & img {
          width: 80%;
          border-radius: 10px;
        }
      }
      & .arrow {
        height: auto;
        padding: 5px;
      }

      & button {
        width: 80%;
        background-color: var(--middle_wood);
        color: var(--cream_light);
        font-size: 1.5rem;
        padding: 15px;
        border-radius: 10px;
        font-weight: 600;
        margin-top: 25px;
        margin-bottom: 25px;
        cursor: pointer;
      }
    }

    & .cover-container {
      position: relative;
      & .cover-text-container {
        top: 0%;
        -ms-transform: translateY(15%);
        transform: translateY(15%);
        position: absolute;
        & .main-name {
          font-size: 11vw;
          width: 100%;
          padding: 5px;
          background-color: transparent;
        }
        & p {
          font-size: 2.5vw;
          margin: -5px auto;
        }
        & .rezerviraj {
          cursor: pointer;
          margin-top: 20vw;
          width: 70%;
          padding: 15px 5px;
          font-size: 5vw;
          font-weight: 300;
          background-color: var(--middle_blue);
          box-shadow: 2px 2px 8px #868686;
        }
      }
    }

    & .what-to-visit-container {
      width: 95%;
      padding: 10px;
      margin: 20px 10px;
      & p {
        font-size: 2rem;
      }
      & .top-part {
        display: block;
        width: 100%;
        & table {
          margin: 8px 0 0 0;
        }
        & .video-responsive {
          margin: 40px auto;
          & iframe {
            width: 100%;
            height: 170px;
          }
        }
      }
      & .description {
        width: 100%;
        font-size: 4.5vw;
        line-height: 150%;
      }
    }

    & .btn-detalji-container {
      width: 100%;
      height: 50px;
      justify-content: center;
      display: flex;
      & .btnDetalji {
        position: static;
      }
    }

    & .map-container {
      & iframe {
        width: fit-content;
        height: 400px;
      }
    }
  }
  .oapartmanu {
    & .about-container {
      & .about-top-container {
        width: 100%;
        display: block;
        & .about-text {
          width: 100%;
          & p {
            font-size: 1rem;
            word-spacing: 0rem;
            line-height: 150%;
          }
        }
        & .slider-container {
          width: 90%;
          margin: 0 auto;
          & img {
            margin: 0 auto;
            border-radius: 5px;
            vertical-align: middle;
          }

          & .slider-container-inner {
            background-color: red;
          }

          & .helper {
            display: inline-block;
            height: 100%;
            vertical-align: middle;
          }
        }
      }

      & .content-container {
        & .content-title {
          padding: 20px;
        }
        & .groups {
          display: block;
          width: 95%;
          margin: 0px auto;
          padding: 0px 0 3px;
          & .left,
          .right {
            padding: 5px;
          }
          & .left {
            width: 100%;
            padding: 5px;
            margin-bottom: 10px;
            & ul {
              font-size: 1.2rem;
              margin-top: 0px;
              margin-left: 20px;
              & li {
                padding: 5px;
                margin-left: 20px;
              }
            }
          }
          & .right {
            width: 100%;
            column-count: 1;
            margin-bottom: 10px;
            padding: 10px;
            & ul {
              & li {
                margin-left: 20px;
              }
            }
          }
        }
      }
      & .more-info-container {
        width: 100%;
        margin: 10px auto;
        font-size: 3rem;
        display: block;
        text-align: center;
        & .info {
          text-align: left;
          margin: 0 20px;
          font-size: 1.1rem;
          & p {
            padding: 5px;
            color: var(--dark_blue);
          }
        }
      }
    }
  }
  .cjenik {
    & table {
      margin: 8px;
      font-size: 1.1rem;
      & th {
        background-color: var(--dark_blue);
        color: var(--cream_light);
        padding: 10px;
      }
      & td {
        padding: 10px;
      }
    }
    & .text-info {
      margin: 10px auto;
      font-size: 1rem;
      width: 95%;
      padding: 5px 20px;
    }
  }

  .omjestu {
    & .title {
      margin: 30px 0;
      font-size: 0.9rem;
    }
    & .zamrsje-container {
      & .cover {
        width: 100%;
        & .description {
          width: 100%;
          padding: 10px;
          font-size: 1.1rem;
          position: static;
          -ms-transform: translateY(0%);
          transform: translateY(0%);
          & p {
            text-align: justify;
            letter-spacing: 0.6px;
            font-weight: 100;
          }
        }
      }
      & .images {
        width: 100%;
        margin: 10px auto;
        display: block;
        & img {
          width: 100%;
        }
      }
      & .text {
        width: 100%;
        margin: 0 auto;
      }

      & .kapela {
        width: 100%;
      }
    }
    & .recica-container {
      & .top-part {
        width: 100%;
        margin: 0;
        padding: 0;
        & .split-divs {
          width: 100%;
          display: block;
          & .old {
            padding: 20px 0px;
            width: 100%;
            & .color-overlay {
              opacity: 70%;
              & img {
                opacity: 100%;
              }
              & .text-over {
                padding: 5px;
                letter-spacing: 1px;
                line-height: 150%;
                font-size: 1.1rem;
                font-weight: 100;
                width: 95%;
              }
            }
          }
          & .new {
            padding: 20px 0;
            width: 100%;
            display: flex;
            & img {
              padding-bottom: 10px;
            }
          }
        }
      }
      & .bottom-part {
        width: 90%;
        margin: 0 auto;
      }
    }
    & .karlovac-container {
      & .cover {
        position: static;
        & .text-container {
          width: 100%;
          padding: 15px;
        }
      }
      & .attractions-container {
        & .attraction {
          width: 100%;
          margin: 20px auto;
          display: block;
          & a {
            display: block;
            & .text {
              text-decoration: none;
              position: static;
              width: 100%;
              & .inside {
                text-decoration: none;
                padding: 25px 15px;
              }
            }
            & .image {
              width: 100%;
              height: 100%;
              padding: 0px;
              & img {
                padding: 0;
                padding: 0px;
                padding-bottom: 5px;
              }
            }
          }
        }
        & .more-info {
          width: 90%;
          font-size: 1rem;
          letter-spacing: 1px;
          line-height: 130%;
        }
      }
    }
    & .other-container {
      & .location {
        margin: 20px auto;
        width: 100%;
        position: static;
        &.zg {
          width: 100%;
        }
        & img {
          margin-top: 10px;
        }
        & .description {
          position: static;
          -ms-transform: translateY(0%);
          transform: translateY(0%);
          padding: 10px;
          width: 100%;
          &.jad {
            width: 100%;
          }
        }
      }
      & .gastro {
        & .gastro-content {
          display: block;
          width: 100%;
          padding: 0px;
          & .gastro-left {
            display: block;
            margin: 25px 0;
            position: static;
            & .down {
              color: var(--dark_blue);
              text-align: center;
              padding: 0;
            }
            & p {
              padding: 10px 25px;
              line-height: 150%;
              letter-spacing: 1.4px;
              font-size: 1.1rem;
            }
            & .left {
              width: 100%;
            }
            & .right {
              width: 100%;
            }
          }
          & .gastro-right {
            margin: 0 auto;
            width: 200px;
            & img {
              width: 200px;
            }
            & p {
              padding: 10px;
            }
          }
        }
        & h2 {
          text-align: center;
          padding: 30px 0 0px;
          font-size: 2rem;
        }
      }
      & .bottom-sentence {
        text-align: center;
        margin: 30px;
        font-size: 1.5rem;
      }
    }
    & .split {
      display: block;
      width: 100%;
      margin: 10px auto;
      position: static;
      & .left {
        width: 100%;
      }
      & .right {
        width: 100%;
        position: relative;
        & p {
          padding: 5px;
        }
      }
    }
  }
  .kontakt {
    & .step-container {
      width: 100%;
      & .text {
        & p {
          font-size: 1.1rem;
          line-height: 150%;
          &.step-title {
            font-size: 1.5rem;
            margin: 20px;
            margin-top: 40px;
          }
        }
      }
      & .step-one {
        & .middle {
          width: 100%;
          display: block;
          & .contact-form-container {
            width: 90%;
            margin: 40px auto;
            & .inputs {
              display: block;
              & label {
                font-size: 1.1rem;
              }
              & textarea {
                height: 250px;
              }
            }
            & #btnSend {
              padding: 10px;
              font-size: 2rem;
            }
          }
          & .info-container {
            position: static;
            top: 0%;
            -ms-transform: translateY(0%);
            transform: translateY(0%);
            //text-align: center;
          }
        }
      }
      & .step-two {
        & .bank-details-container {
          margin: 0 20px;
        }
      }
      & .step-three {
        margin-bottom: 40px;
      }
    }
  }
  .footer-container {
    margin-top: 20px;
    padding: 20px;
    padding-bottom: 50px;
    & .footer-boxes {
      display: block;
      width: 100%;
      & .footer-box {
        margin-top: 10px;
        margin-bottom: 0px;
        padding: 5px;
        width: 100%;
        & .box-title {
          padding: 10px;
          font-size: 1.5rem;
        }
        & .box-data {
          padding: 10px;
          margin: 0px;
        }
      }
    }
  }
  .move-up-middle {
    position: static;
    top: 0%;
    -ms-transform: translateY(0%);
    transform: translateY(0%);
  }
  .info-container {
    margin: 0;
    position: static;
    top: 0%;
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    & p.details {
      text-align: center;
      margin: 0 auto;
    }
  }
  .info-container {
    margin: 10px auto;
    font-size: 1.1rem;
    width: 100%;
    & .item {
      display: flex;
      & .icon {
        width: 50px;
        margin-right: 20px;
        margin-left: 20px;
      }
      & .info {
        max-width: 400px;
      }
    }
  }
}
